@import "@/index.scss";

#echarts {
    // z-index: 9;
    position: relative;
}
.full_screen_btn{
    position: absolute;
    z-index: 9999999999;
    bottom:vh(200);
    right:vw(40);
    cursor: pointer;
}

.demo_largescreen_container {
    height: vh(1080);
    width: vw(1920);
    position: relative;
       background-image: url('~@/assets/diban.png');
       background-size: vw(1920) vh(1080);
       background-repeat: no-repeat;
    .demo_echarts_header {
        position: absolute;
        z-index: 999;
        height: vh(94);
        width: vw(1920);
    }
.map_container{
    background-image: url('~@/assets/demo_brm.jpg');
    background-position: right bottom;
    background-size: vw(894) vh(43);
    background-repeat: no-repeat;
    width: vw(1920);
    height: vh(1080);
    display:flex;
    justify-content: center;
    position: relative;
    .demo_bottom_list{
        position:absolute;
        bottom:vh(26);
        left:vw(581+17+24);
       
    }
}
.map_container_storage{
    background-size: 0 0;
}

    .tj_pictureLayerLeft {
        position: absolute;
        bottom: vh(26);
        left: vw(24);
        z-index: 999;
        width:vw(581);
    }
    .tj_pictureLayerLeft_storage {
        bottom: vh(26);
        width:vw(464);
        height:vh(916);
    }

    .tj_pictureLayerRight {
        position: absolute;
        bottom: vh(26);
        right: vw(24);
        z-index: 999;
        width:vw(581);
    }
    .demo_pictureLayerRight_storage{
        bottom: vh(26);
        width:vw(464);
    }

    .bottom_tips {
        position: absolute;
        bottom: vh(63);
        right: vw(466);
        color: #fff;

        .tips_img {
            width: vw(30);
            height: vh(37);
        }

        &>div>span {
            margin-left: vw(10);
        }

        .origin_goods {
            display: flex;
            align-items: end;
            margin-top: 1px;
            margin-left: vw(3);

            .tips_img {
                width: vw(24);
                height: vh(48);
            }

            span {
                margin-left: vw(13);
            }
        }
    }
}





