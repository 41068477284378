@import "@/index.scss";

#echarts {
    // z-index: 9;
    position: relative;
}

.largescreen_container {
    height: vh(1080);
    width: vw(1920);
    position: relative;

    .echarts_header {
        position: absolute;
        z-index: 999;
        height: vh(120);
        width: vw(1920);
    }

    #echarts {
        background-image: url('~@/assets/bg.jpg');
        background-size: vw(1920) vh(1080);
        background-repeat: no-repeat;
        width: vw(1920);
        height: vh(1080);

    }


    .pictureLayerLeft {
        position: absolute;
        top: vh(114);
        left: vw(50);
        z-index: 999;
    }

    .pictureLayerRight {
        position: absolute;
        top: vh(114);
        right: vw(50);
        z-index: 999;
        // background-color: palegoldenrod;
    }

    .bottom_tips {
        position: absolute;
        bottom: vh(63);
        right: vw(466);
        color: #fff;

        .tips_img {
            width: vw(30);
            height: vh(37);
        }

        &>div>span {
            margin-left: vw(10);
        }

        .origin_goods {
            display: flex;
            align-items: end;
            margin-top: 1px;
            margin-left: vw(3);

            .tips_img {
                width: vw(24);
                height: vh(48);
            }

            span {
                margin-left: vw(13);
            }
        }
    }
}

.mark_modal {
    .ant-modal-content {
        background-color: transparent !important;
    }
    .marker_middle{
        color:#fff;
        .middle_time{
            color: rgba(255,255,255,0.4);
        }
    }
.cover_footer_btn{
    background-color: transparent;
}
}

.echarts-tooltip {
    z-index: 999 !important;
}

.custom_tooltip {
    padding: 0px !important;
    border-width: 0 !important;
    background-color: transparent !important;

    .modal_container {
        background-image: url('~@/assets/single_modal2.png');
        background-repeat: no-repeat;

        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: left right;

        .marker_container {
            .marker_header {
                h2 {
                    color: #fff;
                    width: 130px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .header_title span {
                    color: rgba(255, 255, 255, 0.4);
                    font-size: 8px;

                }

                .header_info {
                    display: flex;
                    flex-wrap: wrap;

                    &>span {
                        color: #BDD5FF;
                        font-size: 10px;
                        background: rgba(255, 255, 255, 0.17);
                        border-radius: 2px;
                        margin-right: 2px;
                        padding: 1px 2px;
                        margin-bottom: 2px;

                        border: 1px solid #276DEE;
                        ;
                    }
                }
            }
         
            .marker_middle {
                flex-direction: column;
                color:#fff;
                &>div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }

                .middle_time {
                    // flex-basis: 120px;
                    color: rgba(255,255,255,0.4);
                    span {
                        padding-right: 4px;
                    }
                }



            }

            #camera_btn {
                width: 100%;
                color: #278FF6;
                text-align: center;
            }
        }

    }


    // 多条信息
    .list_container {
        // width: 169px;
        padding: 10px 0;
        background-color: rgb(18, 30, 69);
        border-radius: 4px;
        border: 1px solid #27B0FF;
        max-height: vh(400);
        overflow-y: auto;

        .item_container {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 5px 12px;

            color: rgba(255, 255, 255, 1);

            .item_code {
                white-space: nowrap;
            }

            img {
                margin-right: 5px;
                width: 16px;
            }
        }

        .item_container:hover {
            background: rgba(39, 176, 255, 0.1);
        }
    }

}