@import "@/index.scss";

.cooperator_rightInventoryOverview {
    width: 100%;
    // height: vh(680);
    background: rgba(0, 123, 162, 0.2);
    border-radius: 2px;
    border: 1px solid #007BA2;
    padding: vh(20) vw(24);
    backdrop-filter: blur(10px);
    .operate {
        width: 100%;
        height: vh(36);
        background-image: url('~@/assets/InTransitImg.png');
        background-size: 100% vh(36);
        background-repeat: no-repeat;
        background-position: left right;
    }

    .earlyWarningTable {
        display: flex;
        justify-content: space-between;
        height: vh(50);
        font-family: df;
        font-weight: normal;
        font-size: vw(14);
        color: #8CA3B6;
        line-height: vh(50);
        text-align: left;
        font-style: normal;
        border-bottom: 1.4px solid rgba(255, 255, 255, 0.3);
    }


    .WarningTable {
        display: flex;
        justify-content: space-between;
        height: vh(44);
        font-family: df;
        font-weight: normal;
        font-size: vw(14);
        color: #FFFFFF;
        line-height: vh(44);
        text-align: left;
    }

    .earlyWarningTableOne {
        width: vw(131);
    }

    .earlyWarningTableTwo {
        width: vw(75);
    }

    .earlyWarningTableThree {
        width: vw(71);
    }

    .earlyWarningTableFour {
        width: vw(167);
    }

   

    .cooperator_scrollBoardBox{
        // margin-top: vh(16);
        font-family: df;
        height: vh(580);

       
        overflow: hidden;
        font-size: vw(14);
        .dv-scroll-board .header{
            color:#8CA3B6;
            padding-bottom: 10px;
            border-bottom: 2px solid rgba(255,255,255,0.3);;
        }
         .dv-scroll-board .rows .row-item {
             font-size: vw(14) !important;
             height: vh(44) !important;
             line-height: vh(44) !important;
         }
    }
    .cooperator_hasedWarehouse{
        height: vh(178);
    }
}
.marginTopData{
    margin-top: vh(16);
}
.operateTwo{
    width: 100%;
    height: vh(36);
    background-image: url('~@/assets/InventoryBusiness.png');
    background-size: 100% vh(36);
    background-repeat: no-repeat;
    background-position: left right;
}