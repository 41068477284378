@import "@/index.scss";

.demo_echarts {
    width: vw(1920);
    height: vh(1080);
}

.demo_echarts_small {
    width: vw(980);
    height: vh(600);
}

.demo_mark_modal {
    .ant-modal-content {
        background-color: transparent !important;
    }

    .marker_middle {
        color: #fff;

        .middle_time {
            color: rgba(255, 255, 255, 0.4);
        }
    }

    .cover_footer_btn {
        background-color: transparent;
    }
}



.demo_custom_tooltip {
    padding: 0px !important;
    border-width: 0 !important;
    background-color: transparent !important;
    z-index: 1001 !important;

    .modal_container {
        background-image: url('~@/assets/single_modal2.png');
        background-repeat: no-repeat;

        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: left right;

        .marker_container {
            .marker_header {
                h2 {
                    color: #fff;
                    width: 130px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .header_title span {
                    color: rgba(255, 255, 255, 0.4);
                    font-size: 8px;

                }

                .header_info {
                    display: flex;
                    flex-wrap: wrap;

                    &>span {
                        color: #BDD5FF;
                        font-size: 10px;
                        background: rgba(255, 255, 255, 0.17);
                        border-radius: 2px;
                        margin-right: 2px;
                        padding: 1px 2px;
                        margin-bottom: 2px;

                        border: 1px solid #276DEE;
                        ;
                    }
                }
            }

            .marker_middle {
                flex-direction: column;
                color: #fff;

                &>div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }

                .middle_time {
                    // flex-basis: 120px;
                    color: rgba(255, 255, 255, 0.4);

                    span {
                        padding-right: 4px;
                    }
                }



            }

            #camera_btn {
                width: 100%;
                color: #278FF6;
                text-align: center;
            }
        }

    }


    // 多条信息
    .list_container {
        // width: 169px;
        padding: 10px 0;
        background-color: rgb(18, 30, 69);
        border-radius: 4px;
        border: 1px solid #27B0FF;
        max-height: vh(400);
        overflow-y: auto;

        .item_container {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 5px 12px;

            color: rgba(255, 255, 255, 1);

            .item_code {
                white-space: nowrap;
            }

            img {
                margin-right: 5px;
                width: 16px;
            }
        }

        .item_container:hover {
            background: rgba(39, 176, 255, 0.1);
        }
    }

}

// 详情样式
.tj_detail_modal {
    .ant-table {
        color: #fff;
        background-color: transparent;
    }

    .ant-modal-header {
        background-color: #062D39;
        border-bottom-color: rgba(255, 255, 255, 0.18);

        .tj_detail_title {
            color: #fff;
            
            // display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .video_btns {
                text-align: right;
                // display:flex;
                flex-wrap: wrap;
                margin-left: 10px;
                justify-content: flex-end;
                font-size: vw(16);
                padding-right: 20px;
                color: #44EBEA;
                position: relative;
                span{
                    display:inline-block;
                    margin-right: 15px;
                    // margin-bottom:10px;
                    padding-bottom:10px;
                    // color:red;
                }
                span:nth-child(1) {
                   
                    font-size: vw(20);
                    align-items: flex-start;
                    justify-content: flex-start;
                    color: #fff;
                    text-align: left;
                    position: absolute;
                    left: 0;
                }

                span:nth-child(2) {
                    margin-left: 80px;
                }
                

            }
        }
    }

    .ant-modal-close {
        color: #fff;
    }

    .ant-table-thead>tr>th {
        background: #062D39;
        opacity: .8;
        font-size: vw(14);
        height: vh(32);
        color: rgba(255, 255, 255, 0.6);
      border: 0px solid rgba(255, 255, 255, 0.18) !important;
        background-color: rgba(255, 255, 255, 0.18);
    }

    .ant-table-tbody>tr>td {
        font-size: vw(14);
        border-bottom-color: rgba(255, 255, 255, 0.18);
        // background: #062D39;
    }

    .ant-table-cell-row-hover {
        background-color: rgba(255, 255, 255, 0.18) !important;
    }
}
.tj_modal_table{
    .ant-empty-image{
        display:none;
    }
    .ant-empty-description{
        color:rgba(255, 255, 255, 0.6);
    }
        .ant-table-tbody>tr>td.ant-table-cell:hover {
            background: #062D39;
        }
}