@import "@/index.scss";

.inventoryBox {
    width: 100%;
    height: vh(940);
    background: rgba(0, 123, 162, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 2px;
    border: 1px solid #007BA2;
    padding: vh(28) vw(24);

    .inventoryOneImg {
        width: 100%;
        height: vh(36);
        background-image: url('~@/assets/inventoryOneImg.png');
        background-size: 100% vh(36);
        background-repeat: no-repeat;
        background-position: left right;

    }

    .inventoryBoxTotal {
        margin-top: vh(25);
        width: 100%;
        height: vh(84);
        background-image: url('~@/assets/inventoryBoxTotalmg.png');
        background-size: 100% vh(84);
        background-repeat: no-repeat;
        background-position: left right;
    }

    .inventoryTotal {
        padding-top: vh(13);
        padding-left: vw(24);
        height: vh(19);
        font-family: df;
        font-weight: normal;
        font-size: vw(16);
        color: #839AAA;
        line-height: vh(19);
    }

    .inventoryNum {
        padding-top: vh(12);
        font-family: df;
        font-weight: normal;
        font-size: vw(28);
        color: #FFFFFF;
        // margin-top: vh(2);
        padding-left: vw(24);
    }

    .InTransitTree {
        display: flex;
        justify-content: space-between;
        margin-top: vh(28);

        .InTransitTreeOne {
            width: vw(130);
            height: vh(76);
            background-image: url('~@/assets/InTransitTreeOne.png');
            background-size: 100% vh(76);
            background-repeat: no-repeat;
            background-position: left right;
        }

        .InTransitTreeTwo {
            width: vw(130);
            height: vh(76);
            background-image: url('~@/assets/InTransitTreeTwo.png');
            background-size: 100% vh(76);
            background-repeat: no-repeat;
            background-position: left right;
        }

        .InTransitTreeT {
            width: vw(130);
            height: vh(76);
            background-image: url('~@/assets/InTransitTreeT.png');
            background-size: 100% vh(76);
            background-repeat: no-repeat;
            background-position: left right;
        }

        .measure {
            margin: vh(8) 0 0 vw(11);
            height: vh(19);
            font-family: df;
            font-weight: normal;
            font-size: vw(16);
            color: #839AAA;
        }

        .measureNub {
            margin: vh(4) 0 0 vw(10);
            font-family: df;
            font-weight: normal;
            font-size: vw(24);
            color: #FFFFFF;
            text-align: left;
            font-style: normal;
        }

    }

    .category {
        margin-left: 1.5%;
        margin-top: vh(20);
        font-family: df;
        font-weight: normal;
        font-size: vw(16);
        color: #D0D4D7;
        line-height: 19px;
        font-style: normal;
    }

    .categoryBorder {
        margin-top: vh(10);
    }

    .categoryBorderLeft {
        margin-top: vh(1.5);
        width: 1%;
        height: vh(2.5);
        background: #3D4F59;
    }

    .categoryBorderCenter {
        width: 20%;
        height: vh(5);
        background: #49A6E3;
    }

    .categoryBorderRight {
        margin-top: vh(1.5);
        width: 79%;
        height: vh(2.5);
        background: #3D4F59;
    }

    .inventoryStatus {
        margin-top: vh(20);
        height: vh(240);
        // background-color: pink;
    }

    .situation {
        margin-top: vh(20);
        height: vh(235);
        // background-color: pink;
    }
}