@import "@/index.scss";

.tj_pictureLayerLeft {
  .tj_left_data {
    width: 100%;
    border-radius: 2px;
    // border: 1px solid #007BA2;
    backdrop-filter: blur(10px);

    .tj_bottom_container,
    .tj_top_container {
      border: 1px solid #007ba2;
      background: rgba(0, 123, 162, 0.2);
      padding: vh(21) vw(14);
      overflow: hidden;
    }

    .tj_top_container {
      margin-bottom: vh(16);
    }

    .leftDataTitle {
      width: 100%;
      height: vh(36);
      background-image: url("~@/assets/dataTitleOne.png");
      background-size: 100% vh(36);
      background-repeat: no-repeat;
      background-position: left right;
    }

    .tradeVolume {
      display: flex;
      margin-top: vh(26);
      height: vh(24);
      font-family: df;
      font-weight: normal;
      font-size: vw(20);
      color: #839aaa;
      line-height: vh(24);
      text-align: left;
      font-style: normal;
      justify-content: space-between;
    }

    .Trade {
      margin-top: vh(16);
      display: flex;
      font-family: df;
      font-weight: normal;
      font-size: vw(36);
      color: #44ebea;

      .TradeData {
        margin-right: vw(10);
        width: vw(33);
        height: vh(50);
        background: rgba(68, 235, 234, 0.1);
        border-radius: 3px;
        line-height: vh(50);
        text-align: center;
      }

      .TradeDataMargin {
        margin-left: vw(10);
      }

      .comma {
        width: vw(33);
        height: vh(50);
        // background: rgba(68, 235, 234, 0.1);
        border-radius: 3px;
        line-height: vh(60);
        text-align: center;
      }
    }

    .inventoryData {
      margin-top: vh(18);
      width: 100%;
      height: vh(138);
      background: linear-gradient(136deg, #111d26 0%, #121e26 47%, #0a4044 100%);
      border-radius: 7px;
      display: flex;
      justify-content: space-between;

      color: #fff;

      .InventoryDataBoxImg {
        padding: 30px;

        .InventoryDataImg {
          width: vw(83);
          height: vh(79);
        }
      }
    }

    .inventoryDataConter {
      padding: vh(18) vw(21);

      .inventoryDataConterOne {
        font-family: df;
        font-weight: normal;
        font-size: vw(18);
        color: #839aaa;
        line-height: 21px;
      }

      .inventoryDataConterTwo {
        padding: vh(6) 0;
        font-family: df;
        font-weight: normal;
        font-size: vw(36);
        color: #ffffff;
        line-height: vh(43);
        letter-spacing: 1px;
      }

      .inventoryDataConterThree {
        font-family: df;
        font-weight: normal;
        font-size: vw(18);

        .monthData {
          color: #839aaa;
          line-height: vh(21);
        }

        .riseData {
          color: #44ebea;
          line-height: vh(21);
        }

        .riseDataImg {
          margin: 0 vw(9);
          width: vw(20);
          height: vh(12);
        }
      }
    }

    .inventoryDataLeftBorder {
      width: vw(7);
      height: vh(138);
      background: #00efec;
      border-radius: 100px 0px 0px 100px;
    }

    .inventoryDataLeft {
      display: flex;
    }

    .category {
      margin-left: 1.5%;
      margin-top: vh(30);
      font-family: df;
      font-weight: normal;
      font-size: vw(16);
      color: #d0d4d7;
      line-height: 19px;
      font-style: normal;
    }

    .categoryBorder {
      margin-top: vh(10);
    }

    .categoryBorderLeft {
      margin-top: vh(1.5);
      width: 1%;
      height: vh(2.5);
      background: #3d4f59;
    }

    .categoryBorderCenter {
      width: 20%;
      height: vh(5);
      background: #49a6e3;
    }

    .categoryBorderRight {
      margin-top: vh(1.5);
      width: 79%;
      height: vh(2.5);
      background: #3d4f59;
    }

    .HistogramChart {
      height: vh(231);
    }

    .operate {
      width: 100%;
      height: vh(36);
      background-image: url("~@/assets/operateImg.png");
      background-size: 100% vh(36);
      background-repeat: no-repeat;
      background-position: left right;
    }

    .PieDataChart {
      height: vh(263);

      .PieDataChartLeft {
        display: flex;
        align-items: center;
        width: vw(354);
        height: vh(263);
      }

      .PieDataChartRight {
        width: calc(100% - vw(354));
      }
    }
  }

  .ProductInventorySituation {
    height: vh(200.3);

    .PieDataChartLeft {
      display: flex;
      align-items: center;
      width: vw(354);
      height: vh(263);
    }

    .PieDataChartRight {
      width: calc(100% - vw(354));
    }
  }

  .operateSituation {
    width: 100%;
    height: vh(36);
    background-image: url("~@/assets/ProductInventorySituation.jpg");
    background-size: 100% vh(36);
    background-repeat: no-repeat;
    background-position: left right;
  }

  .container_box {
    width: vw(30);
    height: vh(40);
  }

  .turn_box_container {
    margin-left: 10px;
    margin-bottom: vh(18);
  }

  .turn_box_container {
    position: relative;
    display: inline-block;
    float: left;
    overflow: hidden;
    background: rgba(68, 235, 234, 0.1);
  }

  .turn_box {
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    width: 100%;
    transform-origin: 0 0;
    transition: top 0.8s;
  }

  .turn_box_number {
    height: vh(40);
    display: grid;
    place-items: center;
    font-size: vw(16);
    font-family: MicrosoftYaHei-Bold;
    font-weight: bold;
    color: #44ebea;
    text-align: center;
  }

  .custom-element {
    color: var(--var);
  }

  .goodsTransit {
    margin-top: vh(12);
    display: grid;
    grid-template-columns: repeat(3, auto);

    .goodsTransitLeft {
      width: vw(113);
      height: vh(46);
      background-image: url("~@/assets/goodsTransitLeft.png");
      background-size: 100% vh(46);
      background-repeat: no-repeat;
      background-position: left right;
    }

    .goodsTransitCenter {
      width: vw(113);
      height: vh(46);
      background-image: url("~@/assets/goodsTransitCenter.png");
      background-size: 100% vh(46);
      background-repeat: no-repeat;
      background-position: left right;
    }

    .goodsTransitRigh {
      width: vw(113);
      height: vh(46);
      background-image: url("~@/assets/goodsTransitRigh.png");
      background-size: 100% vh(46);
      background-repeat: no-repeat;
      background-position: left right;
    }
  }

  .goodsTransitBoxRight {
    margin-left: vw(-54);

    .goodsTransitBoxRightOne {
      // margin-top: vh(2);
      font-family: df;
      font-weight: normal;
      font-size: vw(14);
      color: #839aaa;
      line-height: vh(19);
      text-align: left;
      font-style: normal;
    }

    .goodsTransitNumber {
      margin-top: vh(2);
    }

    .goodsNumber {
      margin-top: vh(35);
      font-family: df;
      font-weight: normal;
      font-size: vw(24);
      color: #ffffff;
      line-height: vh(34);
      text-align: left;
      font-style: normal;
    }

    .goodsUnit {
      font-family: df;
      font-weight: normal;
      font-size: vw(12);
      color: #839aaa;
      line-height: vh(14);
      text-align: left;
      font-style: normal;
    }
  }

  .MonthlyDataSituation {
    // margin-top: vh(18);
    width: 100%;
    height: vh(138);
    margin-bottom: vh(-18);
    // background-color: pink;
  }

  .HistogramChartBoard {
    margin-top: vh(16);
    font-family: df;
    height: vh(180);
    font-size: vw(14);

    // background-color: pink;
    .dv-scroll-board .header {
      color: #8ca3b6;
      // display: none;
      margin-bottom: 3px;
      // border-bottom: 2px solid rgba(255, 255, 255, 0.3);
      background: rgba(0, 123, 162, 0.2) !important;
      // background-color: #007BA2 !important;
    }

    .dv-scroll-board .rows .row-item {
      // padding-top: 3px;
      font-size: vw(14) !important;
      // height: vh(44) !important;
      // line-height: vh(44) !important;
      height: vh(34) !important;
      line-height: vh(34) !important;
    }
        .scroll-board-width {
          width: 100% !important;
        }
  }

  .categoryStorage {
    margin-left: 1.5%;
    margin-top: vh(11);
    font-family: df;
    font-weight: normal;
    font-size: vw(16);
    color: #d0d4d7;
    line-height: 19px;
    font-style: normal;
  }

  .categoryBorderStorage {
    margin-top: vh(10);
  }

  //categoryStorage
  .categoryBorderLeftStorage {
    margin-top: vh(1.5);
    width: 1%;
    height: vh(2.5);
    background: #3d4f59;
  }

  .categoryBorderCenterStorage {
    width: 20%;
    height: vh(5);
    background: #49a6e3;
  }

  .categoryBorderRightStorage {
    margin-top: vh(1.5);
    width: 79%;
    height: vh(2.5);
    background: #3d4f59;
  }

  .categoryStorageBox {
    position: relative;
  }

  .downOutlinedStorage {
    cursor: pointer;
    text-align: right;
    margin-right: 3%;
    color: #49a6e3;
    margin-top: vh(-35);
  }

  .ProductInventorySituationBox {
    z-index: 100;
    margin-top: vh(10);
    background-color: #101d24;
    //
    overflow-y: auto;
    padding: vh(5) 0;
    position: absolute;
    width: vw(546);
  }

  .upHeight {
    height: vh(121);
  }

  .upHeightOut {
    height: auto;
  }

  .categoryStorageBoxLeft {
    font-family: DingTalk, DingTalk;
    font-weight: normal;
    font-size: vw(16);
    color: #d0d4d7;
    height: vh(36);
    line-height: vh(36);
    padding-left: 3%;
  }

  .categoryStorageBoxLeft:hover {
    color: #d0d4d7;
    height: vh(36);
    line-height: vh(36);
    padding-left: 3%;
    background: rgba(255, 255, 255, 0.08);
  }



  /* 自定义整个滚动条 */
  ::-webkit-scrollbar {
    width: 12px;
    /* 设置滚动条的宽度 */
    background-color: #071014;
    /* 滚动条的背景色 */
  }

  /* 自定义滚动条轨道 */
  ::-webkit-scrollbar-track {
    background: #071014;
    /* 轨道的背景色 */
    border-radius: 7px;
    /* 轨道的圆角 */
  }

  /* 自定义滚动条的滑块（thumb） */
  ::-webkit-scrollbar-thumb {
    background-color: #222e35;
    /* 滑块的背景色 */
    border-radius: 7px;
    /* 滑块的圆角 */
    border: 5px solid #222e35;
    /* 滑块边框 */
  }

  /* 滑块hover效果 */
  ::-webkit-scrollbar-thumb:hover {
    background: #222e35;
    /* 滑块hover时的背景色 */
  }

  .warehouseAddress {
    display: flex;
    padding: vh(16) 0;
  }

  .warehouseAddressLeftOne {
    width: vw(182);
    height: vh(95);
    background: linear-gradient(180deg, #101d24 0%, #253e4a 100%);
    border-radius: 2px;
    border: 1px solid #4c6879;
    padding: vh(12) vw(12);
  }

  .warehouseAddressLeftOneOne {
    font-family: DingTalk, DingTalk;
    font-weight: normal;
    font-size: vw(14);
    color: #839aaa;
    line-height: 19px;
  }

  .warehouseAddressLeftOneTwo {
    margin-top: vh(2);
    font-family: DingTalk, DingTalk;
    font-weight: normal;
    font-size: vw(16);
    color: #ffffff;
    line-height: vw(25);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .warehouseAddressLeftTwo {}

  .warehouseAddressRight {
    margin-left: vw(16);
    width: vw(349);
    height: vh(154);
    background: rgba(6, 6, 6, 0.15);
    border-radius: 2px;
    border: 1px solid #4c6879;
    padding: vh(12) vw(12);
  }
  .warehouseAddressRightBottom{
  line-height: vh(134);
  font-family: DingTalk, DingTalk;
    font-weight: normal;
    font-size: vw(16);
    color: #ffffff;
    text-align: center;
  }
}