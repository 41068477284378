@import "@/index.scss";

.leftData {
    width: 100%;
    padding: vh(28) vw(24);
    //  width: vw(300);
    // height: vh(100);
    // font-size: vh(16);
    // background-color: black;
    // margin-left: vw(10);
    // margin-top: vh(10);
    // border: vh(2) solid red;
    height: vh(919);
    background: rgba(0, 123, 162, 0.2);
    border-radius: 2px;
    border: 1px solid #007BA2;
    backdrop-filter: blur(10px);    

    .leftDataTitle {
        width: 100%;
        height: vh(36);
        background-image: url('~@/assets/dataTitleOne.png');
        background-size: 100% vh(36);
        background-repeat: no-repeat;
        background-position: left right;
    }

    .tradeVolume {
        margin-top: vh(26);
        height: vh(24);
        font-family: df;
        font-weight: normal;
        font-size: 20px;
        color: #839AAA;
        line-height: vh(24);
        text-align: left;
        font-style: normal;
    }

    .Trade {
        margin-top: vh(16);
        display: flex;
        font-family: df;
        font-weight: normal;
        font-size: vw(36);
        color:  #44EBEA;;

        .TradeData {
            margin-right: vw(10);
            width: vw(33);
            height: vh(50);
            background: rgba(68, 235, 234, 0.1);
            border-radius: 3px;
            line-height: vh(50);
            text-align: center;
        }

        .TradeDataMargin {
            margin-left: vw(10);
        }

        .comma {
            width: vw(33);
            height: vh(50);
            // background: rgba(68, 235, 234, 0.1);
            border-radius: 3px;
            line-height: vh(60);
            text-align: center;
        }

    }

    .inventoryData {
        margin-top: vh(18);
        width: 100%;
        height: vh(138);
        background: linear-gradient(136deg, #111D26 0%, #121E26 47%, #0A4044 100%);
        border-radius: 7px;
        display: flex;
        justify-content: space-between;


        color: #fff;

        .InventoryDataBoxImg {
            padding: 30px;

            .InventoryDataImg {
                width: vw(83);
                height: vh(79);
            }
        }

    }

    .inventoryDataConter {
        padding: vh(18)  vw(21) ;

        .inventoryDataConterOne {

            font-family: df;
            font-weight: normal;
            font-size: vw(18);
            color: #839AAA;
            line-height: 21px;
        }

        .inventoryDataConterTwo {
            padding: vh(6) 0;
            font-family: df;
            font-weight: normal;
            font-size: vw(36);
            color: #FFFFFF;
            line-height: vh(43);
            letter-spacing: 1px;
        }

        .inventoryDataConterThree {
            font-family: df;
            font-weight: normal;
            font-size: vw(18);

            .monthData {
                color: #839AAA;
                line-height: vh(21);
            }

            .riseData {
                color: #44EBEA;
                line-height: vh(21);
            }

            .riseDataImg {
                margin: 0 vw(9);
                width: vw(20);
                height: vh(12);
            }

        }
    }

    .inventoryDataLeftBorder {
        width: vw(7);
        height: vh(138);
        background: #00EFEC;
        border-radius: 100px 0px 0px 100px;
    }

    .inventoryDataLeft {
        display: flex;
    }



    .category {
        margin-left: 1.5%;
        margin-top: vh(30);
        font-family: df;
        font-weight: normal;
        font-size: vw(16);
        color: #D0D4D7;
        line-height: 19px;
        font-style: normal;
    }

    .categoryBorder {
        margin-top: vh(10);
    }

    .categoryBorderLeft {
        margin-top: vh(1.5);
        width: 1%;
        height: vh(2.5);
        background: #3D4F59;
    }

    .categoryBorderCenter {
        width: 20%;
        height: vh(5);
        background: #49A6E3;
    }

    .categoryBorderRight {
        margin-top: vh(1.5);
        width: 79%;
        height: vh(2.5);
        background: #3D4F59;
    }

    .HistogramChart {
        height: vh(231);
    }

    .operate {
        width: 100%;
        height: vh(36);
        background-image: url('~@/assets/operateImg.png');
        background-size: 100% vh(36);
        background-repeat: no-repeat;
        background-position: left right;
    }

    .PieDataChart {
        height: vh(263);

        .PieDataChartLeft{
            display: flex;
            align-items: center;
            width: vw(354);
            height: vh(263);
        }
        .PieDataChartRight{
            width: calc(100% - vw(354));
        }

    }

}


.container_box{
    width: vw(30);
    height: vh(40);
}
.turn_box_container {
    margin-left: 10px;
    margin-bottom: vh(18);
  }
   
  .turn_box_container {
    position: relative;
    display: inline-block;
    float: left;
    overflow: hidden;
    background: rgba(68, 235, 234, 0.1);
  }
   
  .turn_box {
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    width: 100%;
    transform-origin: 0 0;
    transition: top 0.8s;
  }
   
  .turn_box_number {
    height: vh(40);
    display: grid;
    place-items: center;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold;
    font-weight: bold;
    color: #44EBEA;
    text-align: center;
  }
.custom-element{
    color: var(--var);
}