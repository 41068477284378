@import "@/index.scss";

.demo_echarts{
    width:vw(1920);
    height:vh(1080);
}
.demo_echarts_small{
    width:vw(980);
    height:vh(600);
}
.demo_mark_modal {
    .ant-modal-content {
        background-color: transparent !important;
    }

    .marker_middle {
        color: #fff;

        .middle_time {
            color: rgba(255, 255, 255, 0.4);
        }
    }

    .cover_footer_btn {
        background-color: transparent;
    }
}



.demo_custom_tooltip {
    padding: 0px !important;
    border-width: 0 !important;
    background-color: transparent !important;
    z-index: 1001 !important;

    .modal_container {
        background-image: url('~@/assets/single_modal2.png');
        background-repeat: no-repeat;

        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: left right;

        .marker_container {
            .marker_header {
                h2 {
                    color: #fff;
                    width: 130px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .header_title span {
                    color: rgba(255, 255, 255, 0.4);
                    font-size: 8px;

                }

                .header_info {
                    display: flex;
                    flex-wrap: wrap;

                    &>span {
                        color: #BDD5FF;
                        font-size: 10px;
                        background: rgba(255, 255, 255, 0.17);
                        border-radius: 2px;
                        margin-right: 2px;
                        padding: 1px 2px;
                        margin-bottom: 2px;

                        border: 1px solid #276DEE;
                        ;
                    }
                }
            }

            .marker_middle {
                flex-direction: column;
                color: #fff;

                &>div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }

                .middle_time {
                    // flex-basis: 120px;
                    color: rgba(255, 255, 255, 0.4);

                    span {
                        padding-right: 4px;
                    }
                }



            }

            #camera_btn {
                width: 100%;
                color: #278FF6;
                text-align: center;
            }
        }

    }


    // 多条信息
    .list_container {
        // width: 169px;
        padding: 10px 0;
        background-color: rgb(18, 30, 69);
        border-radius: 4px;
        border: 1px solid #27B0FF;
        max-height: vh(400);
        overflow-y: auto;

        .item_container {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 5px 12px;

            color: rgba(255, 255, 255, 1);

            .item_code {
                white-space: nowrap;
            }

            img {
                margin-right: 5px;
                width: 16px;
            }
        }

        .item_container:hover {
            background: rgba(39, 176, 255, 0.1);
        }
    }

}

// 详情样式
.cooperator_demo_detail_modal {
    .ant-table {
        color: #fff;
        background-color: transparent;
    }

    .ant-modal-header {
        background-color: #062D39;
        border-bottom-color: rgba(255, 255, 255, 0.18);

        .demo_detail_title {
            color: #fff;
            font-size: vw(16);
            .demo_detail_title_btn span{
                font-size: vw(16);
               
                color: #35A7FF;
                line-height: vw(22);
                text-align: left;
                font-style: normal;
                margin-right:vw(12);
            }
        }
    }

    .ant-modal-close {
        color: #fff;
    }
.ant-modal-body{
    padding:20px 24px;
}
    .ant-table-thead>tr>th {
        background: #062D39;
        opacity: .8;
        font-size: vw(14);
        color: rgba(255, 255, 255, 0.6);
        border-bottom-color: rgba(255, 255, 255, 0.18);
        box-shadow: inset 0px -1px 0px 0px rgba(255,255,255,0.18);
    }

    .ant-table-tbody>tr>td {
        line-height: 23px;
        font-size: vw(14);
        border-bottom-color: rgba(255, 255, 255, 0.18);
        box-shadow: inset 0px -1px 0px 0px rgba(255,255,255,0.18);
    }
    .ant-table-cell-row-hover{
        background-color: rgba(255, 255, 255, 0.18)!important;
    }
}