@import "@/index.scss";
.large_screen_top_container{
    display:flex;
    justify-content: center;
    // background:red;
    color:#fff;
    height:100%;
    width:100%;
    background-image: url('~@/assets/header_bg.svg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: left ;
    line-height: vh(100);
    overflow:hidden;
 
    .left_control_btn{
        font-size:vw(20);
        // transform: translate(114px,-6px);
    }
    .title{
        font-size:vw(28);
        height:100%;
        text-align: center;
        // transform: translate(vw(360),-1px);
    }
    .right_info{
        position:fixed;
        top:0;
        right:vh(-80);
        font-size:vw(22);
        // transform: translate(vw(20),vh(-10));
    }
    .temperature {  
       width: vw(280);
       height:vh(100);
    //    position:fixed;
       overflow:hidden!important;
    //    transform: translate(vw(0),vh(10));
       .weather_iframe{
        border:none;
   
        overflow: hidden !important;
        transform: translate(-10px,vh(22));
       }
      }  
        
      .weatherBox{
        background-color: pink;
        width: 50px;
        position: fixed;
        right:50px;
        top:0px;
        z-index: 10;
      }
}