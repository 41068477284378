
@use "sass:math";
@font-face {  
  font-family: 'df'; /* 你给字体起的名字 */  
  src: url('../public/fonts/df.ttf') format('truetype'); /* 字体文件路径 */  
  font-weight: normal;  
  font-style: normal;  
}
// 默认设计稿的宽度
$designWidth: 1920;
// 默认设计稿的高度
$designHeight: 1080;
 
// px 转为 vw 的函数
@function vw($px) {
  @return math.div($px, $designWidth) * 100vw;
}
 
// px 转为 vh 的函数
@function vh($px) {
  @return math.div($px, $designHeight) * 100vh;
}
body {
  margin: 0;
}

#root {
  height: 100%;
}
