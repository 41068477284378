@import "@/index.scss";
.rightModule{
    width: vw(380);
}
.rightModuleTop {
    width: 100%;
    padding:vh(10) 0;
    background: center/cover url('~@/assets/rightTop.png');
    background-size: 100% 100%;
    .Overview {
        height: 28px;
        font-size: vh(19);
        color: #FFFFFF;
        line-height: 28px;
        padding: vh(12) 0;
        margin-left: vw(36);
    }
}
.Origin{
    margin: vh(32) 0;  
    height: vh(198);
    width: 100%;
    background-image: url('~@/assets/rightBag.png');
    background-size: 100% vh(198);
    background-repeat: no-repeat;
    background-position: left right;
    // padding: vh(21) vw(23);
}