@import "@/index.scss";


.demo_top_container {
    display: flex;
    justify-content: space-between;
    // background:red;
    color: #fff;
    height: 100%;
    width: 100%;
    background-image: url('~@/assets/d_header.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: left;
    line-height: vh(94);
    box-sizing: border-box;
    overflow: hidden;
    font-family: 'df';
   
    .maddle_btn {
        font-size: vw(20);
        // transform: translate(114px,-6px);

        .btn {
            color: #898D8F;
        }

        .btn_active {
            color: #fff;
        }

        .ant-select-focused {
            .ant-select-selector {
                border: 1px solid #44EBEA !important;

                box-shadow: -0px -.5px #44EBEA;
            }
        }

        .demo_select_company {
            background: rgba(68, 235, 234, 0.11)!important;
            .ant-select-dropdown {
               
                color: #6FC5C5;
                padding: 6px 0;
            
              
            }
            .ant-select-item {
                background: transparent!important;
                color: #6FC5C5;
            }
            .ant-select-selector {
                background-color: #010518;
                border-radius: 4px;
                border: 1px solid #44EBEA;
                box-shadow: 0px -.5px #44EBEA;

                .ant-select-selection-item {
                    color: #fff;
                }
            }

            .ant-select-selection-placeholder {
                color: rgba(255, 255, 255, 0.40);
            }

            .ant-select-arrow {
                color: #44EBEA;
            }

        }
    }

    .title {
        font-size: vw(48);
        height: 100%;
        text-align: center;
        // transform: translate(vw(360),-1px);
    }

    .right_info {
        position: fixed;
        top: 0;
        right: vh(-80);
        font-size: vw(22);
        // transform: translate(vw(20),vh(-10));
    }

    .left_info {
        position: fixed;
        top: 0;
        left: vh(20);
        font-size: vw(28);
        font-family: "df";
    }

    .temperature {
        width: vw(280);
        height: vh(94);
        //    position:fixed;
        overflow: hidden !important;

        //    transform: translate(vw(0),vh(10));
        .weather_iframe {
            border: none;

            overflow: hidden !important;
            transform: translate(-10px, vh(22));
        }
    }

    .weatherBox {
        background-color: pink;
        width: 50px;
        position: fixed;
        right: 50px;
        top: 0px;
        z-index: 10;
    }
}