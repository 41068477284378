@import "@/index.scss";

// ship modal style
.ship_container {
    // width: vw(217);
    border: 1px solid #FF7F7F;
    // font-family: 'df';
    color: #fff;
    border-radius: 2px;

    .ship_code,.warehouse_detail {
        color: #fff;
        font-size: vw(18);
    }

    .ship_base_info {

        background: rgba(230, 106, 106, 0.36);
        border-radius: 2px;
        border: 1px solid #FF8484;
        padding: 7px 8px 7px 11px;
        backdrop-filter: blur(33px);

        .port_time_content {
            display: flex;
            justify-content: space-between;
            position: relative;

            span {
                border-color: transparent !important;
            }

            .port {
                font-size: vw(10);
                // padding-left:20px;
            }



            .time {
                font-size: vw(9);
                color: rgba(255, 255, 255, 0.4);
            }
        }

        // .port_time_content:before {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 20px;
        //     background-image: url('~@/assets/tooltip_start.svg');
        //     /* 替换为你的图片路径 */
        //     background-size: 100% 20px;
        //     background-position: center;
        // }
    }

    .ship_tags {
        background: rgba(255, 132, 132, 0.13);
        padding: 13px;
        width: vw(217);
        backdrop-filter: blur(50px);

        span {
            box-sizing: border-box;
            display: inline-block;
            max-width: vw(200);
            overflow-y: auto;
            font-size: vw(10);
            background: rgba(255, 255, 255, 0.17);
            border-radius: 2px;
            border: 1px solid #FF8484;
            padding: 0 5px;
        }
    }
}
.port_container {
    // width: vw(217);
    border: 1px solid #FFD884;


    .ship_base_info {
        background: rgba(255, 216, 132, 0.69);
        border: 1px solid #FFD884;
    }

    .ship_tags {
        // background: rgba(152, 87, 25, 0.13);
        span {
            border: 1px solid #FFD884;
  
        }
    }
}
.warehouse_container {
    border: 1px solid #53FF6D;
    h3 {
        color: #fff;
        
    }

    .ship_base_info {
        background: rgba(83, 255, 109, 0.13);
        border: 1px solid #53FF6D;
    }

    .ship_tags {
        // background: rgba(152, 87, 25, 0.13);
        span {

           

            border: 1px solid #53FF6D;
  
        }
    }
}

// 仓库样式 storage
.storage_container_cooperator {
    position: relative;
    z-index: 10;
    background: rgba(83, 255, 109, 0.13);
    border-radius: 2px;
    border: 1px solid #53FF6D;
    font-size: vw(18);
    font-family: 'df';
    color: #fff;

    .storage_name_btn {
        display: flex;
        justify-content: space-between;
        padding: 10px 12px 10px 11px;
        background: rgba(83, 255, 109, 0.36);
        border-radius: 2px;
        border: 1px solid #53FF6D;
        backdrop-filter: blur(33px);

        .detail_btn {

            margin-left: 37px;
            color: #53FF6D;
            text-decoration: underline;
            img{
                width: vw(19);
                // height: vw(16);
            }
        }
    }

    .goods_info_container {
        background: rgba(83, 255, 109, 0.13);
        backdrop-filter: blur(50px);
    }

    .goods_info {
        font-size: vw(14);
        padding: 0 10px;
        position: relative;

        .goods_num {
            color: #53FF6D;
        }

        .bill_item_detail {
            border-color:transparent !important;
            background: rgba(26, 38, 42, 0.9);
            padding: 0;

            .bill_item_detail_container {
                padding: 11px 13px 15px 11px;
                backdrop-filter: blur(50px);
                background: rgba(83, 255, 109, 0.13);
            }

            // backdrop-filter: blur(50px);

            position: absolute;
            right: -10px;
            top: -20px;
            transform: translateX(100%);
            // border: 1px solid #53FF6D;
            width: auto;

            h4 {
                color: #fff;
                font-size: vw(14);
            }

            span {
                font-size: vw(10);
                border: 1px solid #53FF6D;
                padding: 2px 3px;
                margin-right: 4px;
            }

            .bill_item_detail_divider {
                background: #53FF6D;
                height: 1;
                margin: 12px 0;
            }
        }
    }

    .bill_item {
        padding: 7px 10px;

    }
}

// .storage_yard_container
.storage_port_container {
    border: 1px solid #FFD884;
    background: rgba(152, 87, 25, 0.13);

    .storage_name_btn {

        background: rgba(255, 216, 132, 0.69);
        border: 1px solid #FFD884;

        .detail_btn {
            color: #FFD884;
        }
    }

    .goods_info_container {
        background: rgba(152, 87, 25, 0.13);
        backdrop-filter: blur(50px);
    }

    .goods_info {
        position: relative;

        .goods_num {
            color: #FFD884;
        }

        .bill_item_detail {
            background: rgba(15, 7, 0, 0.138);
            // border: 1px solid #FFD884;
            padding: 0;

            .bill_item_detail_container {
                padding: 11px 13px 15px 11px;
                backdrop-filter: blur(50px);
                background: rgba(97, 51, 7, 0.13);
            }

            span {

                border: 1px solid #FFD884;

            }

            .bill_item_detail_divider {
                background: #FFD884;
                height: 1;
                margin: 12px 0;
            }

        }
    }

}

.storage_ship_container {
    border: 1px solid #FF7F7F;
    background: rgba(152, 87, 25, 0.13);

    //FF7F7F FF8484 (230, 106, 106, 0.36)
    .storage_name_btn {

        background: rgba(230, 106, 106, 0.36);
        border: 1px solid #FF7F7F;

        .detail_btn {
            color: #FF7F7F;
        }
    }

    .goods_info_container {
        background: rgba(152, 87, 25, 0.13);
        backdrop-filter: blur(50px);
    }

    .goods_info {
        position: relative;

        .goods_num {
            color: #FF7F7F;
        }

        .bill_item_detail {
            background: rgba(15, 7, 0, 0.138);
            border: 1px solid #FF7F7F;
            padding: 0;

            .bill_item_detail_container {
                padding: 11px 13px 15px 11px;
                backdrop-filter: blur(50px);
                background: rgba(97, 51, 7, 0.13);
            }

            span {

                border: 1px solid #FF7F7F;

            }

            .bill_item_detail_divider {
                background: #FF7F7F;
                height: 1;
                margin: 12px 0;
            }

        }
    }

}

.vedio_icon {
    width: vw(28);
}