@import "@/index.scss";

.cooperator_rightData {
    width: 100%;
    height: vh(926);
    background: rgba(0, 123, 162, 0.2);
    border-radius: 2px;
    border: 1px solid #007BA2;
    // opacity: 0.5;
    padding: vh(28) vw(24);
    backdrop-filter: blur(10px);
    .goodsData {
        width: 100%;
        height: vh(36);
        background-image: url('~@/assets/goodsData.jpg');
        background-size: 100% vh(36);
        background-repeat: no-repeat;
        background-position: left right;
    }

    .cooperator_goodsTransit {
        margin-top: vh(43);
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap:10px;
        .goodsTransitLeft {
            width: vw(113);
            height: vh(46);
            background-image: url('~@/assets/goodsTransitLeft.png');
            background-size: 100% vh(46);
            background-repeat: no-repeat;
            background-position: left right;
        }

        .goodsTransitCenter {
            width: vw(113);
            height: vh(46);
            background-image: url('~@/assets/goodsTransitCenter.png');
            background-size: 100% vh(46);
            background-repeat: no-repeat;
            background-position: left right;
        }

        .goodsTransitRigh {
            width: vw(113);
            height: vh(46);
            background-image: url('~@/assets/goodsTransitRigh.png');
            background-size: 100% vh(46);
            background-repeat: no-repeat;
            background-position: left right;
        }
    }


    .category {
        margin-left: 1.5%;
        margin-top: vh(15);
        font-family: df;
        font-weight: normal;
        font-size: vw(16);
        color: #D0D4D7;
        line-height: 19px;
        font-style: normal;
    }

    .categoryBorder {
        margin-top: vh(10);
    }

    .categoryBorderLeft {
        margin-top: vh(1.5);
        width: 1%;
        height: vh(2.5);
        background: #3D4F59;
    }

    .categoryBorderCenter {
        width: 20%;
        height: vh(5);
        background: #49A6E3;
    }

    .categoryBorderRight {
        margin-top: vh(1.5);
        width: 79%;
        height: vh(2.5);
        background: #3D4F59;
    }

    .goodsTransitBoxRight {
        margin-left: vw(-54);

        .goodsTransitBoxRightOne {
            margin-top: vh(2);
            font-family: df;
            font-weight: normal;
            font-size: vw(16);
            color: #839AAA;
            line-height: vh(19);
            text-align: left;
            font-style: normal;
        }

        .goodsTransitNumber {
            margin-top: vh(5);
        }

        .goodsNumber {
            margin-top: vh(35);
            font-family: df;
            font-weight: normal;
            font-size: vw(28);
            color: #FFFFFF;
            line-height: vh(34);
            text-align: left;
            font-style: normal;
        }

        .goodsUnit {

            font-family: df;
            font-weight: normal;
            font-size: vw(12);
            color: #839AAA;
            line-height: vh(14);
            text-align: left;
            font-style: normal;
        }

    }

    .port {
        height: vh(332);
        width: 100%;
    }

    .inventoryProportion {
        width: 100%;
        height: vh(342);
    }

    .earlyWarning {
        width: 100%;

        // margin-top: vh(16);
        font-family: df;
        height: vh(195);
        font-size: vw(14);
        .dv-scroll-board .header{
            color:#8CA3B6;
            padding-bottom: 10px;
            border-bottom: 2px solid rgba(255,255,255,0.3);;
        }

        .dv-scroll-board .rows .row-item {
            font-size: vw(14) !important;
            height: vh(54) !important;
            line-height: vh(54) !important;
          }
   
    }

    .earlyWarningTable {
        display: flex;
        justify-content: space-between;
        height: vh(50);
        font-family: df;
        font-weight: normal;
        font-size: vw(14);
        color: #8CA3B6;
        line-height: vh(50);
        text-align: left;
        font-style: normal;
        border-bottom: 1.4px solid rgba(255, 255, 255, 0.3);
    }

    .WarningTable {
        display: flex;
        justify-content: space-between;
        height: vh(44);
        font-family: df;
        font-weight: normal;
        font-size: vw(14);
        color: #FFFFFF;
        line-height: vh(44);
        text-align: left;
    }

    .earlyWarningTableOne {
        width: vw(131);
    }

    .earlyWarningTableTwo {
        width: vw(75);
    }

    .earlyWarningTableThree {
        width: vw(71);
    }

    .earlyWarningTableFour {
        width: vw(167);
    }

    .earlyWarningTableFive {
        // width: vw(71);
    }

    .soon {

        color: #F5212D;
    }

    .soonFont {
        font-size: vw(14);
    }
}
.InTransitTree {

    margin-top: vh(28);
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 10px; /* 其他列之间的间隙 */  
 
    .InTransitTreeOne {
        padding-left:12px;
        height: vh(76);
        background-image: url('~@/assets/InTransitTreeOne.png');
        background-size: 100% vh(76);
        background-repeat: no-repeat;
        background-position: left right;

    }

    .InTransitTreeTwo {
    padding-left:12px;
        height: vh(76);
        background-image: url('~@/assets/InTransitTreeTwo.png');
        background-size: 100% vh(76);
        background-repeat: no-repeat;
        background-position: left right;
    }


    .measure {
        margin: vh(8) 0 0 vw(11);
        height: vh(19);
        font-family: df;
        font-weight: normal;
        font-size: vw(16);
        color: #839AAA;
    }

    .measureNub {
        margin: vh(4) 0 0 vw(10);
        font-family: df;
        font-weight: normal;
        font-size: vw(24);
        color: #FFFFFF;
        text-align: left;
        font-style: normal;
    }

}