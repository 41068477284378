@import "@/index.scss";

.leftModule {
    // width: vw(320);
    //  width: vw(300);
    // height: vh(100);
    // font-size: vh(16);
    // background-color: black;
    // margin-left: vw(10);
    // margin-top: vh(10);
    // border: vh(2) solid red;

}

.leftModuleTop {
    // width: 120%;
    background: center/cover url('~@/assets/titleTwo.png');
 background-size: 100% 100%;
    .Overview {
        height: 28px;
        font-size: vh(19);
        color: #FFFFFF;
        line-height: 28px;
        padding: vh(12) 0;
        margin-left: vw(38);
    }
}

// largescreen
.leftModuleTrade {
    margin-top: vh(32);
    // height: 100%;
    width: 100%;
    height: vh(152);

    background-image: url('~@/assets/TotalTrade.png');
    background-size: 100% vh(152);
    background-repeat: no-repeat;
    background-position: left right;

}

.ArrowShapeImg {
    width: 20px;
    height: 20px;
}

.leftModuleTradeTop {
    text-align: center;
    display: flex;
}

.leftModuleTradeRight {
    display: flex;
    justify-content: center;
    font-size: 17px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
}

.tradeBoxData {}

.tradeBoxDataLeft {
    height: 43px;
    font-size: 33px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    // line-height: 43px;
    text-shadow: 0px 3px 3px #003F64;
    background: linear-gradient(180deg, #FFFFFF 0%, #63B3FF 99%);

}

.tradeBoxDataUnit {
    padding-left: 3%;
    height: 23px;
    font-size: 17px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 23px;
}

.YoYLastLeft {
    font-size: vh(15);
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #FFFFFF;
}

.QuantityOfGoodsBottom {
    display: flex;
    padding: vh(20) vw(23) 0 vw(23);
    text-align: center;
}

// InTransit
.InTransitImg {

    width: vw(139);
    height: vh(122);
    background-image: url('~@/assets/InTransit.png');
    background-size: 100% vh(122);
    background-repeat: no-repeat;
    background-position: left right;
}

.InTransitImgTop {
    margin-top: vh(34);
    font-size: vh(25);
    font-family: DINPro-Medium, DINPro;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 28px;
}

.InTransitImgTopBum {
    margin-top: vh(4);
    font-size: vh(15);
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #A0BDF0;
    line-height: 14px;
}

.QuantityOfGoods {

    margin-top: vh(34);
    width: 100%;
    height: vh(306);

    background-image: url('~@/assets/quantityOf.png');
    background-size: 100% vh(306);
    background-repeat: no-repeat;
    background-position: left right;

}

.QuantityOfGoodsTitle {
    padding-left: vw(96);
    padding-top: vh(32);
    font-size: vh(19);
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
}

.QuantityCenterLeft {
    padding-left: vw(96);
    font-size: vh(33);
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 34px;
    text-shadow: 0px 3px 3px #003F64;
    background: linear-gradient(180deg, #FFFFFF 0%, #63B3FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.YoYLastYe {
    font-size: vh(17);
    padding-left: vw(96);
    // line-height: 21px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: 1px transparent;
    color: rgba(255, 255, 255, 0.6);
}

.YoYLastYeRight {
    margin-left: vw(4);
    font-size: vh(18);
    font-weight: 400;
    color: #09D947; //9,217,71
    line-height: 19px;
}








.leftModulWidht {
    margin-top: vh(34);
}

.waybillCycle {
    margin-left: vw(13);
    margin-top: vh(52);
    width: 100%;
    height: vh(208);
    background-image: url('~@/assets/waybill.png');
    background-size: vw(212) vh(208);
    background-repeat: no-repeat;
    background-position: left right;
    display: flex;
}

.cycle {
    padding: vh(74) 0 0 vw(45);
}

.cycleData {
    font-size: vh(30);
    font-weight: 500;
    color: #FFFFFF;
    // line-height: vh(37);
    text-shadow: 0px 3px 3px #003F64;
    background: linear-gradient(180deg, #FFFFFF 0%, #63B3FF 99%);
background-clip: text;
    text-align:center;
    -webkit-text-fill-color: transparent;
}

.cycleDataBom {
    margin-top: -5px;
    font-size: vh(15);
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #FFFFFF;
}

.cycleBottom {
    margin-left: vw(134);
    // padding-left: vw(13);
    margin-top:vh(4);
}

.averagePeriod {
    margin-top: -6px;
    font-size: vh(15);
    color: rgba(255, 255, 255, 0.6);
}

.Period {
    margin-top: -5px;
}

.day {
    margin-left: vw(2);
    font-size: vh(13);
    color: rgba(255, 255, 255, 0.6);
}

.periodData {
    font-size: vh(20);
    font-weight: 500;
    color: #FFFFFF;
}

.cycleBottomCenter {
    margin-top: vw(50);
}

.cycleBottomBottom {
    margin-top: vw(45);
}
.cycleBottomCenter_pc{
    margin-top: vw(40);
}

.cycleBottomBottom_pc {
    margin-top: vw(35);
}