.cloudvison_container {
  position: relative;
}

// iframe 嵌套时 不现实header 地图占据iframe的100vh
.unhead {
  .mapstyle {
    height: 100vh;
  }

  .list_content {
    max-height: calc(100vh - 236px) !important;
    overflow-y: auto;
    // padding: 0px 8px 0 12px;
  }
}

.mapstyle {
  width: 100%;
  height: calc(100vh - 48px);
  overflow: hidden;
  outline: none;
  background-color: #a3ccff;
}



.mapstyle_track {
  width: 100%;
  height: calc(100vh - 48px);
  overflow: hidden;
  outline: none;
  // background-color: #a3ccff;

  .leaflet-popup-content-wrapper.leaflet-popup-content-wrapper {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    background: rgba(1, 102, 255, 0.6);
    border-radius: 4px;
    // border: 2px solid #155BD4;
    // backdrop-filter: blur(6px);

  }

  .tips_container {
    padding: 4px 8px;
    font-size: 12px;

    .tips_port {
      text-align: left;
      margin-bottom: 4px;
    }

    .tips_content {
      text-align: left;
    }
  }



  .leaflet-popup-tip-container {
    display: none;
  }
}

// 不展示head 时 map 高度 100vh;
.unhdead {
  height: 100vh;
}

.layout-content {
  overflow-y: auto;
}

.leftBox {
  width: 33px;
  height: 33px;
  box-shadow: none;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  text-align: center;
  position: absolute;
  padding-top: 3px;
  background: #fff;
  top: 60px;
  left: 10px;
  overflow: hidden;
  z-index: 999;
}

.enterpress_wrap {
  position: absolute;
  top: 7px;
  // left: 10px;
  z-index: 9999;
}

.map_left_container {
  position: absolute;
  top: 24px;
  // left: 10px;
  z-index: 999;
}

.isEnterPress {
  top: 64px;
}

.leftBox-show {
  width: 100px;
  height: 33px;
  box-shadow: none;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  text-align: center;
  position: absolute;
  padding-top: 3px;
  background: #fff;
  top: 60px;
  left: 500px;
  overflow: hidden;
  z-index: 999;
}

.leftBox-hidden {
  width: 100px;
  height: 33px;
  box-shadow: none;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  text-align: center;
  position: absolute;
  padding-top: 3px;
  background: #fff;
  top: 60px;
  left: 600px;
  overflow: hidden;
  z-index: 999;
}

.map_drawer {
  .ant-drawer-header {
    background: #155BD4;
    padding: 16px 8px 16px 16px;
  }

  .ant-drawer-content-wrapper {
    height: 100%;
    overflow-y: auto;
    height: calc(100vh - 92px);

  }

  .ant-drawer-content {
    background: #FFFFFF;
    border-radius: 4px;
    backdrop-filter: blur(6px);
  }

  .ant-drawer-body {
    margin-right: 4px;
    padding: 16px;
  }
}

.map_drawer_unhead {
  margin-top: 20px !important;
  margin-bottom: 20px !important;

  .ant-drawer-content-wrapper {
    height: 100%;
    overflow-y: auto;
    height: calc(100vh - 53px);

  }
}

.drawer_title {
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  height: 34px;
  border-radius: 4px 4px 0px 0px;
  line-height: 34px;

  .ordericon_wrap {
    padding: 5px;
    border-radius: 11px;
    background-color: #fff;
    display: inline-flex;

    img {
      width: 13px;

    }
  }

}

.drawer_sea {
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

// 字体样式
.font12 {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  padding-top: 2px;
}

.font14 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.paddingTop8 {
  padding-top: 8px;
}

// 提示框样式
.map_tip {
  background: white;
  opacity: 1;
  width: 300px;
  padding: 0px 0px;
}

.map_tip1 {
  background: white;
  opacity: 1;
  width: 300px;
  padding: 0px 0px;
}

.map_tip_top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding-bottom: 5px;
  font-size: 14px;
}

.map_tip_top1 {
  border-bottom: 0px solid rgba(0, 0, 0, 0.07);
  padding-bottom: 5px;
  font-size: 14px;
}

.map_img_line {
  margin-top: -4px;
  width: 16px;
}

.tooltip_title {
  cursor: pointer;
}

.tooltip-right-text0 {
  cursor: pointer;
  color: gray;
  // float: right;
}

.float_right {
  float: right;
}

.tooltip-right-text {
  cursor: pointer;
  color: gray;
  // float: right;
}

.info_detail {
  margin-top: 10px;
  border-top: 1px solid #666;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .order_item {
    background: rgb(219 237 255);
    padding: 2px 4px;
    margin: 1px;
  }

  .modal_item {
    margin: 1px 4px;
  }
}

.map-eye {
  padding-left: 50px;
  color: gray;
  float: right;
}

.map_img_eye {
  margin-top: -4px;
  width: 22px;
}

.map_tip_bottom {
  padding-top: 5px;
}

.map_tip_road {
  padding-bottom: 2px;
}

.map_tip_no {
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.map_tip_no1 {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.map_tip_status {
  color: #1c6cdc;
}

.map_tip_bottom_time {
  padding-top: 2px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-between;
}

.map_tip_sea {
  padding-top: 2px;
  font-size: 14px;
  background: transparent;
  text-align: center;
}

.map_tip_sea2 {
  padding-top: 10px;
  font-size: 14px;
  background: transparent;
  text-align: center;
}

.map_tip_sea_right {
  float: right;
  background: transparent;
}

.map_tip_arrowDiv {
  text-align: center;
  display: inline-block;

  background: transparent;
}

.map_tip_arrow {
  margin-top: -3px;
}

.map_tip_sea_left {
  float: left;
  background-color: transparent;
}

.map_tip_ata {
  font-size: 14px;
}

.msk {
  color: black;
  background: #fff;
  font-weight: normal;
  font-size: 12px;
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  padding-left: 2px;
  border-radius: 32px;
  line-height: 32px;
  margin-right: 10px;
}

// 船舶信息
.left_content {
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

.leftSide_ship_title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.expand {
  font-size: 12px;
  color: #1c6cdc;
  font-weight: 500;
}

.drawer_boatInfo {
  padding-top: 14px;
}

.yq-dashboard-txt {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}

.leftSide_row_ship {
  padding-top: 6px;
  padding-left: 10px;
  font-size: 12px;
  line-height: 28px;
}

.leftSide_paddingLeft10 {
  padding-left: 0px;
}

.leftSide_expend {
  float: right;
  color: #1c6cdc;
  cursor: pointer;
  font-size: 12px;
}

.leftSide_port_title {
  font-size: 12px;
  font-weight: 450;
  width: 295px;
}

.leftSide_port_content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.end_port {
  margin-bottom: 8px;
}

.road {
  font-size: 20px;
  color: #1690fe;
  cursor: pointer;
}

.modal-billcode {
  border-bottom: 1px solid #ededed;
}

/* popup弹窗位置 */
.marker_icon {
  transform: translateY(100px) !important;
}

.mypopup {
  width: 260px;
  color: #fff;
  top: -32px !important;
  left: 19px !important;
  // transform: translateY(105px) !important;

}

.mypopup1 {
  width: 160px;
  color: aliceblue;
  top: -36px !important;
  left: -90px !important;
}



.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
}

.bottom_header_up {
  line-height: 36px;
  height: 36px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  cursor: pointer;
}

.bottom_header_txt {
  float: left;
  padding-right: 12px;
  padding-left: 24px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}

.bottom_header_txt1 {
  float: left;
  padding-right: 12px;
  //padding-left: 24px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}

.bottom_header_num {
  float: left;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.95);
}

//货运跟踪
.bottom_row {
  background: #fff;
}

.font14_gray {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.font12_gray {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.font14_blue {
  color: #1c6cdc;
  font-size: 14px;
}

.font12_blue {
  color: #1c6cdc;
  font-size: 12px;
}

.row_HY {
  border-bottom: 0px solid rgba(0, 0, 0, 0.07);
  padding-bottom: 4px;
  padding-top: 4px;
}


.cursor_pointer {
  cursor: pointer;
}

.row_HY_active {
  // background-color: #ededed;
  border-bottom: 0px solid rgba(0, 0, 0, 0.07);
}

.active_bg {
  background-color: #ededed;
}




.borderLeft {
  border-left: 3px solid #1c6cdc;
  display: inline-block;
  line-height: 14px;
  height: 12px;
  padding-right: 7px;
}

.map_detail_order_container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .map_detail_order_btn {
    // position: absolute;
    cursor: pointer;
    margin: auto;
  }
}

.rightImg {
  text-align: center;
  height: 51px;
  line-height: 51px;
  cursor: pointer;
}

// 商品库存
.storehouse_right {
  padding-right: 10px;
  text-align: right;
}

.ant-progress-steps {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-line;
}

.flex_block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.color_block {
  flex-grow: 1;
  background: #dedede;
  width: 5px;
  margin-right: 2px;
  height: 14px;
  color: transparent;
}

.color_block_orange {
  flex-grow: 1;
  background: #ff8800;
  width: 5px;
  margin-right: 2px;
  height: 14px;
  color: transparent;
}

.font12_black {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.font12_warning_h {
  font-size: 12px;
  color: #d9443f;
  font-weight: 500;
}

.bottom_header_down {
  background: #fff;
  line-height: 36px;
  opacity: 1;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.07);
  text-align: center;
}

.down_icon {
  transform: rotate(270deg);
}




//自定义滚动条---库存
.map_drawer .ant-drawer-body::-webkit-scrollbar,
.progress_drawer .ant-drawer-body::-webkit-scrollbar,
.store_drawer .ant-drawer-body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.map_drawer .ant-drawer-body::-webkit-scrollbar-track,
.progress_drawer .ant-drawer-body::-webkit-scrollbar-track,
.store_drawer .ant-drawer-body::-webkit-scrollbar-track {
  background-color: #fff;
}

.map_drawer .ant-drawer-body::-webkit-scrollbar-thumb,
.progress_drawer .ant-drawer-body::-webkit-scrollbar-thumb,
.store_drawer .ant-drawer-body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.16);
}



.edit_tips {
  margin-bottom: 16px;
  padding: 16px;
  background: #ffebeb;
  border-radius: 2px;
  border: 1px solid #df4545;
}

.add_billd_form {
  .ant-form {
    padding-right: 32px;
  }
}

//  船 货信息
.leaflet-popup-content {
  width: auto !important;
  margin: 0 !important;
}

// 单条信息
.marker_container {
  width: 236px;
  // height: 210px;
  padding: 12px;
// background-color: ;
  box-shadow: 0px 8px 45px 0px rgba(0, 0, 0, 0.13);
  border-radius: 4px;

  dl {
    margin: 0;



    dd {
      margin: 0;
    }
  }

  .marker_header {
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    .header_title {
      display: flex;
      justify-content: space-between;

   
    }

    h2 {
      padding: 0 0 5px;
      margin: 0;
      font-size: 14px;
      font-weight: 500;
    }

  


    .transport_sanhuo {
      background-color: #2da641ff;
      color: #fff;
    }

    .transport_container {
      background-color: #0166FF;
      color: #fff;
    }
  }

  .marker_middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    padding-top: 1px;
    cursor: auto;

    // border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    dl {
      display: flex;
      margin-bottom: 10px;
      line-height: 12px;
    }

    dt {
      color: rgba(255, 255, 255, 0.4);
      font-size: 12px;
      padding-right: 5px;
      font-weight: 400;
    }

    dd {
      min-width: 63px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.4);
    }

    .middle_left {
      display: flex;
      font-size: 12px;

    
    }
  }

  .marker_footer {
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    padding-top: 10px;

    i {
      width: 1px;
      height: 12px;
      background: #F5F5F5;
    }

    dl {
      display: flex;
      align-items: center;

    }

    dt {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      text-align: center;
      padding-right: 4px;
    }

    dd {
      font-size: 12px;
      font-weight: 400;
      color: #155BD4;
    }

  }
}

.custom_drawer {

  .ant-descriptions-row>td,
  .ant-descriptions-row>th {
    padding-bottom: 8px;
  }

  .ant-divider-vertical {
    margin: 0 6px 0 0;
  }

  .ant-descriptions-header {
    margin-bottom: 10px;
  }
}



.drawer_empty,
.progress_drawer,
.store_drawer {
  overflow: hidden;
  // max-height: calc(100vh - 32px);

  .ant-drawer-content-wrapper {
    height: 167px !important;
    width: 362px;
    top: calc(100vh - 245px);
    right: 232px;

    .ant-drawer-content {
      background: #FFFFFF;
      box-shadow: 4px 4px 20px 0px rgba(28, 108, 220, 0.2);
      border-radius: 4px;
    }

    .ant-drawer-header {
      padding: 12px 16px;
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      line-height: 20px;

      .ant-drawer-title {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        line-height: 20px;
      }

      .ant-drawer-extra {
        &>span {
          cursor: pointer;
          padding: 0 8px;
          color: rgba(0, 0, 0, 0.25);
        }
      }

    }

    .ant-drawer-body {
      padding: 0;
      margin: 0;
      margin-bottom: 4px;
    }
  }

  .custom_steps::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .custom_steps::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0;
    // background: red;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.16);
  }

  .custom_steps {
    margin-top: 10px;
    margin-left: -17PX;
    padding: 12px 14px;
    font-size: 10px;
    width: auto;




    .ant-steps-item-content {
      width: auto !important;
      margin-top: 0;
      height: auto;
    }

    .ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
      margin-left: 0;
    }

    .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
      width: 100%;
    }

    //  图标
    .ant-steps-item-icon {
      position: relative;
      z-index: 9;
      min-width: 72px;
      margin-left: 0 !important;
      width: 100% !important;

      .ant-steps-icon {
        font-size: 18px !important;
      }

    }

    // 横线
    .ant-steps-item-tail {
      z-index: 1;
      top: 0;
      left: 50%;
      margin: 0;
      padding: 0 15px 0 15px;
    }

    .ant-steps-item:last-child {
      flex: 1 1;
    }

    .ant-steps-item-finish .ant-steps-item-tail::after {
      background-color: #0166FF;
      height: 1px;
    }

    .ant-steps-item-finish .ant-steps-item-tail::after {
      background-color: #0166FF;
      height: 1px;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail:after {
      background-color: #BAD2F4;
    }

    .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail:after {
      background-color: #bbb8b8;
    }

    .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
      color: #BAD2F4;
    }

    .ant-row {
      background-color: #FF8800;
    }

    // 文本
    .ant-steps-item-title {
      font-size: 8px;
      line-height: 10px;
      margin-top: 8px;
    }
  }
}

// 左面list 438 右面监控 232 394
// .progress_drawer {
//   .ant-drawer-content-wrapper {
//     width: calc(100vw - 670px) !important;

//   }
// }

// 右面 监控有值时

// .progress_drawer {
//   .ant-drawer-content-wrapper {
//     width: calc(100vw - 670px) !important;

//   }
// }

.store_drawer {

  .ant-drawer-content-wrapper {
    width: 362px !important;
    right: 16px;
  }

  .icon_quantity {
    min-width: 90px;
    position: absolute;
    left: 50%;
    top: -20px;
    font-size: 12px;
    color: #0166FF;
    transform: translate(-50%);
  }


  .ant-steps-item-content {
    width: 100% !important;

    margin-top: 0;
    height: auto;
  }

  .custom_steps_store {
    margin-left: -4px;
    // max-width: 100px;

    width: 100%;




    .ant-steps-item-icon {
      padding: 0;
      margin: 0;
      // min-width: 90px;
      // max-width: 100px;
      background: #fff;
      margin-left: 0 !important;

      .ant-steps-icon {
        font-size: 18px !important;
        position: relative;

        .last_child {
          position: relative;
          right: 0;
          top: 0;
          // margin-left: 10px;
        }
      }

    }

    // 横线
    .ant-steps-item-tail {
      padding: 0;
    }

    .steps_icon_wrap {
      background: #fff;
    }
  }


}

.drawer_empty {
  .ant-drawer-content-wrapper {
    width: 200px !important;
    height: 167px;
    top: calc(100vh - 245px);
    right: 232px;
  }

}

.store_drawer_empty {
  .ant-drawer-content-wrapper {
    right: 16px;
  }
}

.cover_footer_btn {
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 12px;
  left: 0;
  width: 100%;
  height: 32px;
  background: #fff;

  dl {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;

    dt {
      margin-right: 4px;

    }

    dd {
      color: #0166FF;
      font-size: 12px;
      padding: 0;
      margin: 0;
    }
  }

  i {
    width: 1px;
    height: 12px;
    background: #F5F5F5;
  }
}

.show_drawer_btn {
  position: fixed;
  z-index: 999;
  color: #fff;
  top: 20px;
  left: -4px;
  width: 72px;
  height: 54px;
  padding-left: 16px;
  line-height: 54px;
  background: #0166FF;
  border-radius: 4px;

  span {
    // padding: 7px 10px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: #fff;
  }

}

.custom_drawer_title {
  display: flex;
  justify-content: space-between;


}

.hide_drawer {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}

.empty_store {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 12.5px;

  div {
    margin-top: 8px;
  }
}

.custom_track_steps {
  .ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
    margin-right: 12px;
  }

  .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #155BD4;
  }

  .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #155BD4;
  }

  .ant-steps-item-finish .ant-steps-item-tail::after {
    background-color: #155BD4;
    width: 2px !important;
  }
}

.custom_header {
  font-weight: 400;
}

.store_drawer_content {
  margin-top: 20px;
}

.custome_popup {
  .leaflet-popup-content-wrapper {
    border-radius: 4px;
  }
}