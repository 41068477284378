@import "@/index.scss";

.tj_scrollBoard_container {
    height: vh(303);
    overflow: hidden;
    // height: 100%;
    width: vw(329);
    font-family: "df";
    color: #D0D4D7;
    background: rgba(0, 123, 162, 0.2);
    border-radius: 2px;
    border: 1px solid #007BA2;
    backdrop-filter: blur(10px);
    padding: vw(20) vw(24) vw(14);
    .tj_list_header{
       
        height: vh(50);
        margin-bottom: 10px;
        padding: 0 10px;
        font-size: vw(14);
        color: #fff;
        font-weight: bold;
    }
    .tj_list_header::after {
        margin-top:vh(13);
        display:block;
        content: "";
        // position: absolute;
        // bottom: 0;
        // left: 0;
        width: 100%;
        height: 2px;
        background-image: url('~@/assets/blue_line.png');
        /* 替换为你的图片路径 */
        background-size: 100% 2px;
        background-position: center;
        // clip-path: inset(2px); /* 根据需要调整这个值以改变“边框”的宽度 */  
    }
    .dv-scroll-board .rows .row-item {
        font-size: vw(14) !important;
        height: vh(30) !important;
        line-height: vh(30) !important;
        background-color: transparent !important;
        margin-bottom:vh(14)!important;
    }

    .row-item {
        background-color: transparent !important;
        height: 100% !important;
        line-height: 100% !important;
        margin-bottom: 8px;
    }
    .dv-scroll-board .rows .ceil{
        padding:0;
    }
}


.tj_item_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    border-bottom: 1px solid transparent !important;
    background-color: rgba(0, 123, 162, 0.2);
}

.tj_demo_item {
    display: flex;
    width: 100%;
    height: vh(30);
    justify-content: space-between;
    flex-wrap: nowrap;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%);

    .item_left {
        display:flex;
        align-items: center;
        i {
            display: inline-block;
            background: rgba(255, 255, 255, 0.08);
            height: 100%;
            width: vh(30);
            margin-right: 10px;
            text-align: center;
            padding: 2px 6px;
        }
        span{
            display: inline-block;
            width: vw(180);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.tj_demo_item0 {
    background: linear-gradient(90deg, rgba(231, 60, 58, 0.3) 0%, rgba(231, 60, 58, 0) 100%);

    .item_left {
        i {
            background: #E73C3A;
            
        }
    }
}

.tj_demo_item1 {
    background: linear-gradient(90deg, rgba(228, 108, 52, 0.3) 0%, rgba(228, 108, 52, 0) 100%);

    .item_left {
        i {
            background: #E46C34;
           
        }
    }
}

.tj_demo_item2 {
    background: linear-gradient(90deg, rgba(223, 152, 41, 0.3) 0%, rgba(223, 152, 41, 0) 100%);

    .item_left {
        i {
            background: #DF9829;
          
        }
    }
}

.demo_List_container {
    padding: 0 24px 14px;
    width: vw(329);
    font-family: "df";
    color: #D0D4D7;
    background: rgba(0, 123, 162, 0.2);
    border-radius: 2px;
    border: 1px solid #007BA2;
    backdrop-filter: blur(10px);

    .ant-list-header {
        position: relative;
        margin-bottom: 14px;
    }

    .ant-list-header::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-image: url('~@/assets/blue_line.png');
        /* 替换为你的图片路径 */
        background-size: 100% 2px;
        background-position: center;
        // clip-path: inset(2px); /* 根据需要调整这个值以改变“边框”的宽度 */  
    }

    .ant-list-items {
        .ant-list-item {
            color: #fff;
            width: vw(281);
            margin-bottom: 14px;
            padding: 4px 0;

            .demo_item {
                display: flex;
                width: 100%;
                justify-content: space-between;
                flex-wrap: nowrap;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%);

                .item_left {
                    i {
                        background: rgba(255, 255, 255, 0.08);
                        padding: 2px 6px;
                    }
                }
            }

            .demo_item0 {
                background: linear-gradient(90deg, rgba(231, 60, 58, 0.3) 0%, rgba(231, 60, 58, 0) 100%);

                .item_left {
                    i {
                        background: #E73C3A;
                        padding: 2px 6px;
                    }
                }
            }

            .demo_item1 {
                background: linear-gradient(90deg, rgba(228, 108, 52, 0.3) 0%, rgba(228, 108, 52, 0) 100%);

                .item_left {
                    i {
                        background: #E46C34;
                        padding: 2px 6px;
                    }
                }
            }

            .demo_item2 {
                background: linear-gradient(90deg, rgba(223, 152, 41, 0.3) 0%, rgba(223, 152, 41, 0) 100%);

                .item_left {
                    i {
                        background: #DF9829;
                        padding: 2px 6px;
                    }
                }
            }
        }
    }
}